<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/agent.png" alt />
        <span class="center">权限管理</span>

        <img src="../../assets/right.png" alt />
        <span class="center">导入/导出</span>
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>资源统计</span>
            <span style="float:right">
              <a
                type="text"
                @click.once="exportData"
                style="font-size:14px;margin:0 10px;color:#4977fc;text-decoration: none;"
              >导出</a>
              <el-button type="text" @click="dialogVisible = true">导入</el-button>
            </span>
          </div>
          <div style="text-align:left">
            <p>资源：{{ accessCount }}条</p>
            <p>角色：{{ resourceCount }}条</p>
            <p>授权：{{ roleCount }}条</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="导入配置文件"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <input
        type="file"
        @change="afterUpload"
        style="width:0;height:0;position:absolute"
        name
        id="ramUpload"
      />

      <div style="margin-bottom:25px">
        <p>
          <b>授权：</b>
          <i>{{ importAccessCount }}条</i>
        </p>
        <p>
          <b>资源：</b>
          <i>{{ importResourceCount }}条</i>
        </p>
        <p>
          <b>角色：</b>
          <i>{{ importRoleCount }}条</i>
        </p>
      </div>
      <el-row>
        <el-button size="small">
          <label for="ramUpload">选择配置文件</label>
        </el-button>
        <el-button size="small" type="primary" :disabled="!importJson" @click="importData">开始导入</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '/src/api/base';
import { generatePath } from '../../api/base/index';
import moment from 'moment'

export const statisticsList = api('')('/role.ram.data.export.info.json');
export const roleRamDataExport = api('')('/role.ram.data.export.json');
export const roleRamDataImport = api('')('/role.ram.data.import.json');
export default {
  data () {
    return {
      dialogVisible: false,
      accessCount: 0,
      resourceCount: 0,
      roleCount: 0,
      importAccessCount: 0,
      importResourceCount: 0,
      importRoleCount: 0,
      importJson: null
    };
  },
  mounted () {
    this.loadData();
  },
  methods: {
    loadData () {
      const params = {};
      statisticsList(params).then(res => {
        this.accessCount = res.accessCount
        this.resourceCount = res.resourceCount
        this.roleCount = res.roleCount
      });
    },
    async exportData () {
      const time = moment().format('yyyy-MM-DD HHmmss')
      const data = await roleRamDataExport({})
      var blob = new Blob([JSON.stringify(data)]);
      var link = document.querySelector('a');
      console.log(link);
      link.href = URL.createObjectURL(blob);
      link.download = `${generatePath}.${time}.json`;

      link.click();
    },
    afterUpload ($event) {
      const file = $event.target.files[0]
      $event.target.value = null
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (ev) => {
        var jsonStr = ev.target.result;
        this.importJson = JSON.parse(jsonStr);
        this.importAccessCount = this.importJson.access.length
        this.importResourceCount = this.importJson.resources.length
        this.importRoleCount = this.importJson.roles.length
      };
    },
    handleClose (e) {
      console.log(e);
    },
    async importData () {
      const loading = this.$loading({ text: '配置文件上传中，请稍后', fullscreen: true, background: 'rgba(0, 0, 0, 0.8)' })
      await roleRamDataImport(this.importJson)
      this.importAccessCount = 0
      this.importResourceCount = 0
      this.importRoleCount = 0
      this.importJson = null
      loading.close()
      this.dialogVisible = false
      this.loadData()
    },
  }
};
</script>
<style lang="scss" scoped></style>

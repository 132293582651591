import { render, staticRenderFns } from "./exportResources.vue?vue&type=template&id=485cd23e&scoped=true&"
import script from "./exportResources.vue?vue&type=script&lang=js&"
export * from "./exportResources.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485cd23e",
  null
  
)

export default component.exports